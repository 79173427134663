<template>
  <card-modal
    :visible="visible"
    title="Unscheduled Model"
    @ok="ok"
    @close="close"
    @cancel="cancel">
    <div
      class="content"
      style="min-height: 50vh">
      <div class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Make</label>
            </div>
            <div class="field-body">
              <div class="field">
                <bulma-autocomplete
                  v-model="entity.make"
                  :data="makes"
                  :loading="isLoading"
                  @input="search"
                  :is-valid="!$v.entity.make.required"
                  name="make"
                  :expanded="true"
                  :type="$v.entity.make.required? '' : 'is-danger'" />
                <span
                  v-if="!$v.entity.make.required"
                  class="help is-danger">Make is required</span>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Model</label>
            </div>
            <div class="field-body">
              <div class="field has-validation is-narrow has-addons">
                <p class="control">
                  <input
                    @change="checkModel"
                    class="input"
                    type="text"
                    placeholder="Model"
                    v-model="entity.model"
                    v-fq-validate="$v.entity.model"
                    ref="model">
                  <span
                    v-if="!$v.entity.model.required"
                    class="help is-danger">Model is required</span>
                </p>
                <p class="control">
                  <button
                    v-if="modelCheck.isLoading && entity.model !== ''"
                    class="button is-success">
                    <span class="icon">
                      <i class="mdi mdi-loading mdi-spin" />
                    </span>
                  </button>

                  <button
                    v-if="!modelCheck.isLoading && modelCheck.exist && entity.model !== ''"
                    class="button is-danger tooltip"
                    :data-tooltip="modelCheck.active? 'Exists and active' : 'Exists and inactive'">
                    <span class="icon">
                      <i class="mdi mdi-alert" />
                    </span>
                  </button>

                  <button
                    v-if="!modelCheck.isLoading && !modelCheck.exist && entity.model !== ''"
                    class="button is-success">
                    <span class="icon">
                      <i class="mdi mdi-check" />
                    </span>
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Cylinders</label>
            </div>
            <div class="field-body">
              <div class="field is-narrow">
                <p class="control">
                  <!-- <input class="input" type="number" placeholder="e.g: 5" v-model="entity.cylinders" min="0" v-fq-validate="$v.entity.cylinders"> -->
                  <the-mask
                    class="input"
                    placeholder="e.g. 6"
                    v-model="entity.cylinders"
                    :mask="['###']" />

                  <span
                    class="help is-danger"
                    v-if="$v.entity.cylinders.$error">Please enter between 1 and 100</span>
                </p>
              </div>
            </div>
          </div>

          <hr>
          <h1 class="title is-5">Production Years</h1>

          <div class="field is-horizontal">

            <div class="field-body">
              <div class="field has-addons">
                <p class="control">
                  <button
                    class="button is-static"
                    disabled>From</button>
                </p>
                <p class="control">
                  <span class="select">
                    <select v-model="entity.startMonth">
                      <option
                        value="null"
                        disabled>MM</option>
                      <option
                        v-for="(value, key) in months"
                        :value="key"
                        :key="key">{{ value }}</option>
                    </select>
                  </span>
                </p>
                <p class="control">
                  <!-- <input class="input" type="number" placeholder="YYYY" v-model="entity.startYear" style="width: 10ch" min="1900"> -->
                  <the-mask
                    class="input"
                    placeholder="e.g. 2000"
                    v-model="entity.startYear"
                    :mask="['####']"
                    @blur.native="checkStartYear" />
                </p>
                <p class="control">
                  <button
                    class="button is-static"
                    disabled>To</button>
                </p>
                <p class="control">
                  <span class="select">
                    <select v-model="entity.endMonth">
                      <option
                        value="null"
                        disabled>MM</option>
                      <option
                        v-for="(value, key) in months"
                        :value="key"
                        :key="key">{{ value }}</option>
                    </select>
                  </span>
                </p>
                <p class="control">
                  <!-- <input class="input" type="number" placeholder="YYYY" v-model="entity.endYear" style="width: 10ch" min="1900"> -->
                  <the-mask
                    class="input"
                    placeholder="e.g. 2000"
                    v-model="entity.endYear"
                    :mask="['####']"
                    @blur.native="checkEndYear" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-narrow body-style-container">
          <p class="title is-5">Body Styles</p>
          <p
            class="subtitle is-6"
            :class="{'body-style-error': bodyStyleEmpty}">({{ picked }}/{{ pickBodyStyles.length }} Selected)</p>
          <ul>
            <li
              v-for="(b, index) in pickBodyStyles"
              :key="index">
              <input
                type="checkbox"
                class="is-checkradio"
                :id="index"
                v-model="b.selected">
              <label :for="index">{{ b.name }}</label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </card-modal>
</template>

<script>
import CarModelService from './CarModelService'
import { required, between } from 'vuelidate/lib/validators'
import { InputValidationDirective } from '@/components/directives'

import { CardModal } from '@/components/BulmaModal'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'

import _debounce from 'lodash/debounce'
import Guid from '@/components/Guid'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'UnscheduledModelModal',
  components: {
    TheMask,
    CardModal,
    BulmaAutocomplete
  },
  mixins: [InputValidationDirective],
  props: {
    visible: Boolean,
    name: String,
    bodyStyles: {
      type: Array,
      default: () => []
    },
    copyModelId: String,
    modelId: String
  },
  data() {
    return {
      isLoading: false,
      modelCheck: {
        isLoading: false,
        exist: false,
        active: false
      },
      months: {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
        6: '06',
        7: '07',
        8: '08',
        9: '09',
        10: '10',
        11: '11',
        12: '12'
      },
      entity: {
        id: Guid.newGuid(),
        isNew: true,
        make: '',
        model: '',
        bodies: '',
        cylinders: null,
        startMonth: null,
        startYear: null,
        endMonth: null,
        endYear: null,
        active: true
      },
      colours: [],
      pickBodyStyles: [],
      makes: [],
      bodyStyleEmpty: false
    }
  },
  computed: {
    picked: function() {
      return this.pickBodyStyles.filter(d => d.selected).length
    },
    entityMake: function() {
      return this.entity.make
    }
  },
  validations: {
    entity: {
      make: {
        required
      },
      model: {
        required
      },
      cylinders: {
        between: between(1, 100)
      }
    }
  },
  mounted: async function() {
    if (this.copyModelId) {
      const req = await CarModelService.getCarModel(this.copyModelId)
      // handle 404?
      // convert to new model
      this.entity = req.data
      this.entity.id = Guid.newGuid()
      this.entity.isNew = true
    } else if (this.modelId) {
      const req = await CarModelService.getCarModel(this.modelId)
      // handle 404?
      this.entity = req.data
    }

    if (this.name) {
      this.entity.make = this.name
    }

    this.pickBodyStyles = this.bodyStyles.map(d => {
      return {
        selected: this.entity.bodies.indexOf(d.id) > -1,
        id: d.id,
        name: d.name
      }
    })
  },
  methods: {
    search: _debounce(async function() {
      this.makes = []

      if (this.entity.make === '' || this.entity.make.length < 2) {
        return
      }

      this.checkModel()

      this.isLoading = true

      const rs = await CarModelService.searchMakes(this.entity.make)

      this.isLoading = false

      this.makes = rs.data.list
    }, 500),
    checkModel: _debounce(async function() {
      this.modelCheck.isLoading = true

      const rs = await CarModelService.checkNameExists(this.entity.id, this.entity.make, this.entity.model)

      this.modelCheck.isLoading = false

      if (rs) {
        this.modelCheck.exist = rs.data.exist
        this.modelCheck.active = rs.data.active
      } else {
        this.modelCheck.exist = false
        this.modelCheck.active = false
      }
    }, 500),
    checkStartYear(e) {
      this.entity.startYear = this.getYear(e.target.value)
    },
    checkEndYear(e) {
      this.entity.endYear = this.getYear(e.target.value)
    },
    getYear: function(year) {
      if (year.length < 4) {
        const strYear = `01/01/${year}`
        const newDate = new Date(strYear)
        return newDate.getFullYear()
      } else {
        return year
      }
    },
    close() {
      // If this is not here
      // Dialog will not show up if [x] or outside of modal is clicked
      this.visible = false
    },

    async ok() {
      const bodies = this.pickBodyStyles.filter(d => d.selected).map(d => d.id)

      if (bodies.length > 0) {
        this.entity.bodies = bodies
        this.bodyStyleEmpty = false
      } else {
        this.bodyStyleEmpty = true
      }

      if (this.$v.error || this.bodyStyleEmpty) {
        this.$notification.error('Validation Errors', 'Please fix highlighted fields')
      } else {
        const carReq = await CarModelService.save(this.entity)

        const carModelReq = await CarModelService.getCarModel(carReq.data.id)
        const carModel = carModelReq.data
        carModel.isNew = false

        this.$emit('ok', carModel)
        this.visible = false
      }
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.body-style-container {
  border-left: 1px solid #e9e9e9;
  margin-left: 1em;
  padding-left: 1em;
  ul {
    border-top: 1px dashed #e9e9e9;
    border-bottom: 1px dashed #e9e9e9;
    max-height: 40vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0 0.5em;

    li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }
}

.body-style-error {
  color: red;
}
</style>
