import { required, maxLength } from 'vuelidate/lib/validators'

const customMinLength = (value, vehicle) => {
  if (vehicle && vehicle.bodyId && vehicle.bodyId.toUpperCase() === 'O') {
    return true
  } else if (value !== null || value != undefined) {
    return value.length >= 7 || value.length === 0
  }
  return true
}

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    entity: {
      rego: {
        required
      },
      customer: {
        required
      },
      model: {
        required
      },
      bodyId: {
        required
      },
      bodyId2: {
        required
      },
      paintGroup: {
        required
      },
      vin: {
        customMinLength: customMinLength,
        maxLength: maxLength(17)
      }
    }
  }
}
