import FqApi from '@/services/fq-api'

export default {
  searchMakes(searchKey) {
    return FqApi.get('/carmodels/makes?key=' + searchKey)
  },

  getCarModel(id) {
    return FqApi.get(`/carmodels?id=${id}`)
  },

  getCarModels(filter) {
    return FqApi.post('/carmodels/list', filter)
  },

  checkNameExists(id, make, model) {
    if (make && model && make !== '' && model !== '') {
      return FqApi.get(`/carmodels/check-exist?id=${id}&make=${make}&model=${model}`)
    }

    return null
  },

  save(carModel) {
    if (carModel.isNew) {
      return FqApi.post('/carmodels', carModel)
    } else {
      return FqApi.put(`/carmodels?id=${carModel.id}`, carModel)
    }
  }
}
