<template>
  <div class="field">
    <label class="label">Company / Name</label>
    <div class="field has-addons">
      <div class="control is-expanded">
        <multiselect
          v-if="customers"
          :class="{ 'is-danger' : $v.entity.customer.$error }"
          :value="value"
          @input="changeCustomer"
          deselect-label="Can't remove this value"
          track-by="key"
          label="value"
          placeholder="Select a customer"
          :options="customers"
          :loading="isCustomerLoading"
          :internal-search="false"
          :options-limit="assetFilter.pageSize"
          @search-change="searchCustomer"
          :searchable="true"
          :show-labels="false"
          :allow-empty="true">
          <template
            slot="option"
            slot-scope="props">
            <span>{{ props.option.value }}</span>
          </template>
          <span
            class="has-text-danger"
            slot="noResult">Customer not found.</span>
        </multiselect>
        <span
          v-if="!$v.entity.customer.required"
          class="help is-danger">Company / name is required</span>
      </div>
      <p class="control">
        <a
          class="button is-success tooltip"
          data-tooltip="Add customer"
          @click="addCustomer"
          v-if="!customerExists">
          <span class="icon is-small">
            <i class="mdi mdi-plus mdi-24px" />
          </span>
        </a>
        <a
          class="button is-primary tooltip"
          data-tooltip="Edit customer"
          @click="editCustomer"
          v-if="customerExists">
          <span class="icon is-small">
            <i class="mdi mdi-pencil mdi-24px" />
          </span>
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import _debounce from 'lodash.debounce'
import CustomerService from '@/views/customer/CustomerService'
import { InputValidationDirective } from '@/components/directives'
import StoreMixin from './storeMixin'
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'

export default {
  name: 'VehicleCustomer',
  components: {
    Multiselect
  },
  mixins: [InputValidationDirective, StoreMixin],
  inject: ['$vv'],
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      customers: [],
      isCustomerLoading: false,
      customerExists: false,
      assetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        pageIndex: 1,
        pageSize: 50
      }
    }
  },
  computed: {
    $v() {
      return this.$vv
    },
    customerId: function() {
      if (this.value && this.value.key) {
        return this.value.key
      }

      return ''
    },
    customerText: function() {
      if (this.value && this.value.value) {
        return this.value.value
      } else {
        return ''
      }
    }
  },
  mounted() {
    if (this.customerId !== '') {
      this.customerExists = true

      this.customers.push(this.value)
    } else {
      this.customerExists = false
      this.getCustomersDropdown()
    }
  },
  methods: {
    getCustomersDropdown: async function(query) {
      this.isCustomerLoading = true
      if (query && this.value) {
        this.$emit('input', null)
      }

      this.assetFilter.name = query
      this.customers = await CustomerService.getCustomerDropdownProto(this.assetFilter)

      this.isCustomerLoading = false
    },
    searchCustomer: _debounce(function(val) {
      if (val === '') {
        return
      }

      if (this.value && this.value.value) {
        if (val === this.value.value) {
          return
        }
      }

      this.$emit('input', null)
      this.customerExists = false

      this.getCustomersDropdown(val)
    }, 500),
    changeCustomer(val) {
      if (val) {
        this.customerExists = true
      } else {
        this.customerExists = false
      }

      this.$emit('input', val)
    },
    async addCustomer() {
      this.setCustomerReturnRoute(this.$route)
      this.addStoreCustomer(this.assetFilter.name)
    },
    editCustomer() {
      this.setCustomerReturnRoute(this.$route)
      this.editStoreCustomer(this.customerId)
    }
  }
}
</script>
