<template>
  <div class="field">
    <label class="label">Model</label>
    <div class="field has-addons">
      <p class="control">
        <a class="button is-primary"
          @click="searchModelPopup">
          <span class="icon is-small">
            <i class="mdi mdi-magnify mdi-24px" />
          </span>
        </a>
      </p>
      <p class="control is-expanded">
        <bulma-autocomplete :value="modelText"
          :data="models"
          field="name"
          @input="searchModel"
          @select="changeModel"
          :keep-first="true"
          :loading="searchingModel"
          :expanded="true"
          :type="$v.entity.model.required? '' : 'is-danger'">
          <template slot-scope="props">
            <div>
              <div class="tags is-pulled-right">
                <span class="tag is-info">
                  {{ props.option.cylinders }} Cylinders
                </span>
                <span class="tag is-info">
                  {{ props.option.production }}
                </span>
              </div>
            </div>
            <span>{{ props.option.name }}</span>
          </template>
        </bulma-autocomplete>
        <span v-if="!$v.entity.model.required"
          class="help is-danger">Model is required</span>
      </p>
      <p class="control">
        <a class="button is-success tooltip"
          data-tooltip="Add unscheduled model"
          @click="addModel"
          v-if="modelId === ''">
          <span class="icon is-small">
            <i class="mdi mdi-plus mdi-24px" />
          </span>
        </a>
        <a class="button is-primary tooltip"
          data-tooltip="Edit unscheduled model"
          @click="editModel"
          v-if="modelId !== '' && entity.model.isUnscheduled">
          <span class="icon is-small">
            <i class="mdi mdi-pencil mdi-24px" />
          </span>
        </a>
        <a class="button is-success tooltip"
          data-tooltip="Create unscheduled model"
          @click="copyModel"
          v-if="modelId !== '' && !entity.model.isUnscheduled">
          <span class="icon is-small">
            <i class="mdi mdi-pencil mdi-24px" />
          </span>
        </a>
      </p>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field"
          v-if="entity && entity.model">
          <p class="tags">
            <span class="tag is-info"
              v-if="entity.model.cylinders">{{ entity.model.cylinders }} Cylinders</span>
            <span class="tag is-info">{{ entity.model.production }}</span>
            <span class="tag is-info"
              v-if="entity.model.isUnscheduled">Unscheduled</span>
          </p>
        </div>
      </div>
      <div class="column is-flex is-justify-content-flex-end">
        <div v-show="ntarLtarStatus"
          class="field">
          <span class="tag is-success animate__animated animate__pulse animate__repeat-3 tooltip is-tooltip-bottom"
            :data-tooltip="`${ntarLtarStatus} schedule and times found`">{{ ntarLtarStatus }} Hours On</span>
        </div>
      </div>
    </div>

    <car-model-search-modal :visible="showVehicleSearchModal"
      @ok="selectModel"
      @cancel="showVehicleSearchModal = false" />
  </div>
</template>
<script>
import Vue from 'vue'
import VehicleService from './VehicleService'
// import _debounce from 'lodash.debounce'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { InputValidationDirective } from '@/components/directives'
import UnscheduledSearchModal from '@/components/carmodel/UnscheduledModelModal'
import { CarModelSearchModal } from '@/components/carmodel'
import StoreMixin from './storeMixin'

export default {
  name: 'VehicleModel',
  inject: ['$vv'],
  components: {
    BulmaAutocomplete,
    CarModelSearchModal
  },
  mixins: [InputValidationDirective, StoreMixin],
  props: {
    entity: null
  },
  data() {
    return {
      models: [],
      modelText: '',
      searchingModel: false,
      showVehicleSearchModal: false,
      ntarLtarStatus: ''
    }
  },
  computed: {
    $v() {
      return this.$vv
    },
    modelId: function () {
      if (this.entity && this.entity.model && this.entity.model.id) {
        return this.entity.model.id
      }
      return ''
    }
  },
  watch: {
    'entity.model': {
      handler: function (newVal, oldVal) {
        this.modelText = this.entity.model.name
        this.getNtarLtarStatus()
      },
      deep: true
    },
    'entity.bodyId': {
      handler: async function (newVal, oldVal) {
        this.getNtarLtarStatus()
      }
    },
    'entity.bodyId2': {
      handler: async function (newVal, oldVal) {
        this.getNtarLtarStatus()
      }
    }
  },
  created() {
    this.getNtarLtarStatus()
  },
  mounted() {
    if (this.entity && this.entity.model && this.entity.model.name) {
      this.modelText = this.entity.model.name
    }
  },
  methods: {
    searchModel: function (val) {
      console.log('search ', val)
      this.modelText = val
      this.models = []

      if (val === '') {
        return
      }

      if (this.entity && this.entity.model && this.entity.model.name) {
        if (val === this.entity.model.name) {
          return
        }
      }

      this.searchingModel = true
      VehicleService.searchModelByName(val).then((res) => {
        this.models = res.data.list
        this.searchingModel = false
      })
    },
    // searchModel: _debounce(function(val) {
    //   console.log('search ' + val)
    //   this.modelText = val
    //   this.models = []
    //
    //   if (val === '') {
    //     return
    //   }
    //
    //   if (this.entity && this.entity.model && this.entity.model.nameWithCylinders) {
    //     if (val === this.entity.model.nameWithCylinders) {
    //       return
    //     }
    //   }
    //
    //   this.searchingModel = true
    //   VehicleService.searchModelByName(val).then(res => {
    //     this.models = res.data.list
    //     this.searchingModel = false
    //   })
    // }, 500),
    changeModel(val) {
      console.log(`${val} - ${this.modelText}`)
      if (val) {
        this.entity.model = val
      } else if (!val && this.modelText === '') {
        this.entity.model = null
      }
    },
    addModel() {
      const UnscheduledSearchModalComponent = Vue.extend(UnscheduledSearchModal)
      const props = {
        el: document.createElement('div'),
        propsData: {
          visible: true,
          name: this.modelText,
          bodyStyles: this.storeBodyShapes // this.$store.getters['vehicleOptions/bodyShapes']
        }
      }
      new UnscheduledSearchModalComponent(props).$on('ok', (res) => {
        this.modelText = res.name
        this.entity.model = res
        this.entity.model.isNew = false
      })
    },
    copyModel() {
      const UnscheduledSearchModalComponent = Vue.extend(UnscheduledSearchModal)
      const props = {
        el: document.createElement('div'),
        propsData: {
          visible: true,
          bodyStyles: this.storeBodyShapes, // this.$store.getters['vehicleOptions/bodyShapes'],
          copyModelId: this.entity.model.id
        }
      }
      new UnscheduledSearchModalComponent(props).$on('ok', (res) => {
        this.modelText = res.name
        this.entity.model = res
        this.entity.model.isNew = false // ??
      })
    },
    editModel() {
      if (!this.entity.model.isUnscheduled) {
        return
      }

      const UnscheduledSearchModalComponent = Vue.extend(UnscheduledSearchModal)
      const props = {
        el: document.createElement('div'),
        propsData: {
          visible: true,
          bodyStyles: this.storeBodyShapes, // this.$store.getters['vehicleOptions/bodyShapes'],
          modelId: this.entity.model.id
        }
      }
      new UnscheduledSearchModalComponent(props).$on('ok', (res) => {
        this.modelText = res.name
        this.entity.model = res
      })
    },
    searchModelPopup: function () {
      this.showVehicleSearchModal = true
      // const SearchComponent = Vue.extend(CarModelSearchModal)
      // const props = {
      //   el: document.createElement('div'),
      //   propsData: {
      //     visible: true
      //   }
      // }
      // new SearchComponent(props).$on('ok', res => {
      //   this.modelText = res.name
      //   this.entity.model = res
      // })
    },
    selectModel(model) {
      if (model) {
        this.modelText = model.name
        this.entity.model = model
      }
      this.showVehicleSearchModal = false
    },
    async getNtarLtarStatus() {
      this.ntarLtarStatus = await VehicleService.getNtarLtarStatusByScheduleBody(this.entity.model.scheduleId, this.entity.bodyId)
    }
  }
}
</script>
