<template>
  <div v-if="entity">
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-delete="entity.isActive && !entity.isNew"
      :show-print="!entity.isNew"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <app-side-menu :min-width="18"
          :fixed-menu="false">
          <aside class="menu"
            slot="side-menu">
            <p class="menu-label">
              <span class="title is-6">
                <strong>{{ (entity.rego)? entity.rego : 'Rego' }}</strong>
              </span>
            </p>
            <ul class="menu-list">
              <li>
                <router-link :to="{ name: routeTypes.VehicleDetail.name, query: $route.query }"
                  active-class="is-active">
                  <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.$invalid }"
                    :data-badge="$v.$invalid ? '' : null">
                    Detail Info
                  </span>
                </router-link>
              </li>
            </ul>
          </aside>
        </app-side-menu>
      </div>
      <div class="column">
        <router-view :entity="entity"
          @toggleActive="onToggleActive" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true, true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Vehicle is in use</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entity.rego }}</span> is in use. Set to
        <span class="tag is-danger">In-Active</span> anyway?
      </p>
    </confirm-modal>
    <confirm-modal :active.sync="isToggleModalActive"
      @ok="deleteEntity(true, false)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Vehicle is in use</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ entity.rego }}</span> is in use. Set to
        <span class="tag is-danger">In-Active</span> anyway?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import VehicleService from './VehicleService'
import AppDetailHeader from '@/components/AppDetailHeader'
import AppSideMenu from '@/components/AppSideMenu'
import VehicleFormValidation from './VehicleFormValidation'
import VehicleRoutes from './route-types'
import QuoteRoutes from '@/views/quote/route-types'
import CustomerRoutes from '@/views/customer/route-types'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'VehicleView',
  components: {
    AppDetailHeader,
    AppSideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [VehicleFormValidation, StoreMixin],
  props: {
    isNew: Boolean
  },
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isToggleModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null
    }
  },
  computed: {
    validateError() {
      this.$v.$touch()
      return this.$v.$error
    },
    routeTypes() {
      return VehicleRoutes
    },
    customerRoutes() {
      return CustomerRoutes
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  async created() {
    this.getEntity()
  },
  methods: {
    async onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.checkBeforeDelete()
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()

        if (reset) {
          this.clearSnapshots(this.$route.params.vehicleId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.vehicleId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'Vehicle'

      if (this.validateError) {
        this.$notification.validationError(title)
        return false
      }

      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))

        if (this.entity.isNew) {
          response = await VehicleService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          response = await VehicleService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', 'Save', 'No changes. Not saved')
        }

        this.$hideSpinner()

        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} set to In-Active` : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} set to In-Active` : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, `New  ${title}  added`)
        }
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError(title, e)
        }
        return false
      }
    },
    async deleteEntity(confirmDelete, save) {
      this.isConfirmModalActive = false
      this.isToggleModalActive = false
      if (confirmDelete) {
        this.entity.isActive = false
        // save snapshots immediately not waiting for debounce
        if (save) {
          this.save(true)
        }
      } else {
        this.entity.isActive = true
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.$route.query.returnUrl) {
          const url = `${this.$route.query.returnUrl}?true&params=${this.$route.query.params}&vid=${this.$route.params.vehicleId}`
          window.location.href = url
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: VehicleRoutes.VehicleListView.name
          })
        }
      }
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    print() {
      const params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        VehicleID: this.entity.id
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.id
      emailer.subject = 'Vehicle Table Detail'
      emailer.reportName = 'rptTableVehicleDetails'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.getEntity(true)
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    async checkBeforeDelete() {
      this.$showSpinner()
      const isInUse = await VehicleService.assertUsed(this.entity.id)
      this.$hideSpinner()
      if (isInUse) {
        this.isConfirmModalActive = true
      } else {
        this.deleteEntity(true, true)
      }
    },
    async onToggleActive() {
      if (this.entity.isActive) {
        // Reserved for future action
      } else {
        this.$showSpinner()
        const isInUse = await VehicleService.assertUsed(this.entity.id)
        this.$hideSpinner()
        if (isInUse) {
          this.isToggleModalActive = true
        } else {
          this.deleteEntity(true, false)
        }
      }
    }
  },
  beforeRouteLeave: function(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))

    const isEditCustomer = to.name === this.customerRoutes.CustomerDetail.name

    if (isEditCustomer) {
      next()
    } else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      console.log(this.snapshotDiff)
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.id)
      if (to.name === QuoteRoutes.QuoteDetail.name) {
        this.setQuoteVehicleCustomer({
          vehicleId: !this.entity.isNew ? this.entity.id : this.$guid.empty(),
          customerId: this.entity.customer && !this.entity.isNew ? this.entity.customer.key : this.$guid.empty()
        })
      }
      this.setReturnRoute({})
      next()
    }
  }
  // Cant destroy the session in case it's going to customer page
  // beforeDestroy() {
  //   sessionStorage.removeItem(this.entity.id)
  // }
}
</script>
<style type="scss">
/*Prevent label line wrapping in vehicle detail page*/

/*Original flex-grow is 5*/

.field-body {
  flex-grow: 3;
}
</style>
