<template>
  <article>
    <p class="title">Engine</p>

    <div class="field">
      <label class="label">VIN</label>
      <p class="control">
        <the-mask
          name="vin"
          v-model="entity.vin"
          :mask="vinMask"
          :tokens="vinTokens"
          class="input is-uppercase"
          v-fq-validate="$v.entity.vin" />
      </p>
      <span class="help is-danger"
        v-if="!$v.entity.vin.customMinLength">Vin should have at least 7 characters</span>
      <span class="help is-danger"
        v-if="!$v.entity.vin.maxLength">Vin max 17 characters</span>
    </div>

    <div class="field">
      <label class="label">Engine no</label>
      <p class="control">
        <input
          class="input"
          type="text"
          placeholder="ABCD1234"
          v-model="entity.engineNo">
      </p>
    </div>

    <div class="field">
      <label class="label">No. of cylinders</label>
      <p class="control">
        <!-- <input class="input" type="number" placeholder="6" max="100" v-model="entity.cylinders"> -->
        <the-mask
          class="input"
          placeholder="e.g. 6"
          v-model="entity.cylinders"
          :mask="['###']" />
      </p>
    </div>

    <div class="field">
      <label class="label">Engine size</label>
      <p class="control">
        <!-- <input class="input" type="text" placeholder="e.g. 1300" max="100" v-model="entity.engineSize"> -->
        <the-mask
          class="input"
          placeholder="e.g. 1300"
          v-model="entity.engineSize"
          :mask="engineSizeMask"
          :tokens="engineSizeTokens" />
      </p>
    </div>
  </article>
</template>
<script>
import { InputValidationDirective } from '@/components/directives'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'VehicleEngine',
  inject: ['$vv'],
  components: {
    TheMask
  },
  mixins: [InputValidationDirective],
  props: {
    entity: null
  },
  data() {
    return {
      vinTokens: {
        '#': {
          pattern: /[0-9a-zA-Z]/
        }
      },
      vinMask: Array(18).join('#'), // length of vin field
      engineSizeTokens: {
        P: {
          pattern: /[0-9, .]/
        }
      },
      engineSizeMask: Array(5).join('P')
    }
  },
  computed: {
    $v() {
      return this.$vv
    }
  }
}
</script>
